
























































// @vuese
// @group SimplyVideoAdmin
import Vue from 'vue';

import AccountsTable from '../../components/tables/AccountsTable.vue';
import CustomersTable from '@/components/tables/CustomersTable.vue'
import FinancialSnapshots from '@/components/cards/FinancialSnapshots.vue'
import Component from 'vue-class-component';
import TabbedCard from '@/components/cards/TabbedCard.vue'


@Component({
  components: {
    AccountsTable,
    CustomersTable,
    FinancialSnapshots,
    TabbedCard
  },
  metaInfo: {
    title: 'Dashboard',
  }
})
/**
 * Home page for SimplyVideo Admins
 */
export default class Home extends Vue {

  componentTitle = 'Dashboard'
  totalCustomers = 0 // placeholder
  totalLicenses = 0 // placeholder
  monthlyRevenue = '£0.00' // placeholder
  conversionRate = 0 // placeholder
  upcomingRenewals = 0 // placeholder

  selectedDistributor = {
    id: 0,
    account_id: 0,
    name: '',
    last_name: '',
  }
  selectedReseller = {
    id: 0,
    account_id: 0,
    business_name: '',
  }

  tabs = [
    {id: 'distributors', title: 'distributors'},
    {id: 'resellers', title: 'all Resellers'},
    {id: 'companies', title: 'all Companies'}
  ]

  showResellers(disti: any) {
    this.selectedDistributor = disti
  }

  showCompanies(reseller: any) {
    this.selectedReseller = reseller
  }

  clearDistributor() {
    this.clearReseller()
    this.selectedDistributor = {
      id: 0,
      account_id: 0,
      name: '',
      last_name: '',
    }
  }

  clearReseller() {
    this.selectedReseller = {
      id: 0,
      account_id: 0,
      business_name: '',
    }
  }
}

