

























interface Tab {
  id: string,
  title: string,
}

// @vuese
// @group Card
// Tabbed Card

import Component from "vue-class-component";
import { Prop, Emit } from 'vue-property-decorator';
import Vue from "vue";

@Component
/**
 * Card Which Has Tabs To Flip Between Views That Are Given In Prop-defined Slots
 */
export default class TabbedCard extends Vue {
  currentTabId: string = '';
  @Prop() readonly tabs!: Array<Tab>
  @Prop() readonly sectionID!: string

  /**
   * Fire when a tab is clicked
   */
  @Emit()
  selected(tab:string) {
    return tab
  }

  mounted() {
    this.currentTabId = this.tabs ? this.tabs[0].id : ''
    this.selected(this.currentTabId)
  }

  showTab(tab: Tab) {
    this.currentTabId = tab.id
    this.selected(this.currentTabId)
  }

  isCurrentTab(thisTab: Tab): boolean {
    return thisTab.id === this.currentTabId
  }
}
